import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import { View, Card, Text, Button, Spacer, Link as OlluiLink } from "ollui";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { Author } from "./Content";

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <View extend={rootCSS}>
        {posts &&
          posts.map(({ node: post }) => (
            <Card as="article" key={post.id} extend={postCSS}>
              {post.frontmatter.featuredimage ? (
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.frontmatter.featuredimage,
                    alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                  }}
                  extend={imageCSS}
                />
              ) : null}
              <Spacer size={2} />
              <View extend={infoCSS}>
                <Link to={post.fields.slug}>
                  <Text
                    variant="medium"
                    bold
                    extend={{ textDecoration: "none" }}
                  >
                    {post.frontmatter.title}
                  </Text>
                </Link>
                <Author date={post.frontmatter.date} extend={authorCSS} />
                <Spacer size={4} />
                <Text>{post.frontmatter.description}</Text>
                <Spacer size={3} />
                <OlluiLink
                  as={Link}
                  to={post.fields.slug}
                  extend={{ textDecoration: "none" }}
                  variant="clear"
                >
                  Keep Reading →
                </OlluiLink>
              </View>
            </Card>
          ))}
      </View>
    );
  }
}

const rootCSS = ({ theme: { grid, layout, breakpoints } }) => ({
  // display: "grid",
  // gridTemplateColumns: "1fr 1fr",
  // gridGap: 4 * grid,
  width: "100%",
  maxWidth: layout.maxContentWidth,
  margin: "0 auto",
  padding: 4 * grid,

  // [breakpoints.onlyS]: {
  //   gridTemplateColumns: "1fr",
  // },
});

const postCSS = ({ theme: { grid, colors, shadows, breakpoints } }) => ({
  display: "flex",
  padding: 2 * grid,
  overflow: "hidden",
  border: `2px solid ${colors.border}`,

  "&:hover": {
    boxShadow: shadows.small,
  },

  [breakpoints.onlyS]: {
    flexDirection: "column",
  },
  // height: 300,
  // marginBottom: 4 * grid,
});

const imageCSS = ({ theme: { grid, colors, shadows, breakpoints } }) => ({
  // width: "50%",
  // maxWidth: 200,
  width: 200,
  height: 200,
  borderRadius: "50%",
  objectFit: "contain",
  background: "orange",
  border: `5px solid ${colors.border}`,
  boxShadow: shadows.big,
  transition: "all 200ms ease-in-out",
  // flexShrink: 0,
  "&:hover": {
    boxShadow: shadows.small,
  },

  [breakpoints.onlyS]: {
    // flexDirection: "column",
    margin: "0 auto",
  },
});

const infoCSS = ({ theme: { grid } }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: 3 * grid,
  textDecoration: "none",
});

const authorCSS = ({ theme: { grid, breakpoints } }) => ({
  marginTop: 3 * grid,

  [breakpoints.fromM]: {
    position: "absolute",
    top: 4 * grid,
    right: 4 * grid,
    flexDirection: "row-reverse",
    marginTop: 0,
  },
});

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);

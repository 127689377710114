import React from "react";

import Layout from "../../components/layout";
import BlogRoll from "../../components/BlogRoll";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <BlogRoll />
      </Layout>
    );
  }
}
